<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <debug-btn :item="responseData"></debug-btn>
          <template v-slot:heading>
            <h3>{{title}}</h3>
          </template>
          <v-row class="justify-center">
            <v-btn-toggle mandatory v-model="triwulan" class="pt-5" v-if="isAsisten">
              <v-btn small color="red white--text">Verifikasi SKP</v-btn>
              <v-btn small color="yellow darken-1">TRIWULAN 1</v-btn>
              <v-btn small color="yellow darken-1">triwulan 2</v-btn>
              <v-btn small color="yellow darken-1">triwulan 3</v-btn>
              <v-btn small color="yellow darken-1">triwulan 4</v-btn>
              <v-btn small value="tahun" color="green darken-3 white--text">tahunan</v-btn>
              <v-btn small value="skpd" color="primary white--text">Kinerja Organisasi</v-btn>
            </v-btn-toggle>
            <v-btn-toggle mandatory v-model="triwulan" class="pt-5" v-else>
              <v-btn color="red white--text">Verifikasi SKP</v-btn>
              <v-btn color="yellow darken-1">TRIWULAN 1</v-btn>
              <v-btn color="yellow darken-1">triwulan 2</v-btn>
              <v-btn color="yellow darken-1">triwulan 3</v-btn>
              <v-btn color="yellow darken-1">triwulan 4</v-btn>
              <v-btn value="tahun" color="green darken-3 white--text">tahunan</v-btn>
            </v-btn-toggle>
          </v-row>
          <v-row class="pt-5">
            <v-col cols="2"></v-col>
            <v-col cols="2">
              <div class="d-flex justify-end">
                <label>NIP Bawahan: </label>
              </div>
            </v-col>
            <v-col>
              <div class="justify-center">
              <v-autocomplete
                v-model="nip_bawahan"
                :items="dataBawahan"
                label="Pilih Bawahan/Rekan Kerja Anda"
                :item-text="item => item.peg_nip == 'all' ? item.peg_nama : item.peg_nip +' - '+ item.peg_nama"
                item-value="peg_nip"
                dense
                outlined
                :loading="loadingBtn.dataBawahan"
                @change="getBawahanSKPPerNIP"
              >
              </v-autocomplete>
              </div>
            </v-col>
            <v-col cols="3"></v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center">
              <h1>{{ triwulan == 1 || triwulan == 2 || triwulan == 3 || triwulan == 4 ? 'Penilaian SKP Triwulan '+triwulan : triwulan == 'tahun' ? 'Penilaian SKP Tahunan' : triwulan == '' ? 'Verifikasi SKP' : 'Penilaian Kinerja Organisasi' }}</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="pegawai.length > 0">
              <div v-for="(peg,i) in pegawai" :key="i + 'a'">
                <div v-if="triwulan != 0">
                  <v-row class="pt-5" v-if="peg.skp.length > 0">
                    <v-col class="d-flex justify-center">
                      <v-avatar size="120">
                        <v-img :src="peg.foto" :alt="peg.username"></v-img>
                      </v-avatar>
                      <div
                        style="max-width:350px"
                      >
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title class="headline mb-1">
                              {{peg.nama}}
                            </v-list-item-title>
                            <v-list-item-subtitle>NIP. {{peg.nip}}</v-list-item-subtitle>
                            <div class="mb-1">
                              {{peg.jabatan}}
                            </div>
                            <div class="mb-1">
                              <!-- <span v-if="peg.penilaian_organisasi">Kinerja Organisasi Sudah Dinilai</span> -->
                              <div v-if="peg.penilaian_organisasi">
                                <v-chip label color="green white--text" class="mb-3" small>Kinerja Organisasi Sudah Dinilai</v-chip>
                              </div>
                              <div v-else>
                                <v-btn v-if="triwulan == 'skpd'" :disabled="peg.penilaian_organisasi ? true : false" @click="openDialogKinerjaOrganisasi(peg)" small color="primary darken-1">Input Nilai Kinerja Organisasi</v-btn>
                              </div>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row v-if="peg.skp.length > 0">
                    <v-col>
                      <v-data-table :headers="headers" :items="peg.skp" hide-default-footer :items-per-page="peg.skp.length">
                        <template v-slot:item.jenis>
                            Triwulan {{triwulan}}
                        </template>

                        <template v-slot:item.sasaran="{item}">
                          <div v-if="item.rencana_kinerja != null">
                            {{ item.rencana_kinerja }}<br>
                            <i v-if="year >= 2022"><strong><font color="black">(Format: Permenpan No. 6/2022)</font></strong></i>
                            <i v-else><strong><font color="black">(Format: Permenpan No. 8/2021)</font></strong></i>
                          </div>
                          <div v-else>
                            {{item.sasaran || item.sasaran_kinerja_tahunan || item.rencana_kinerja}}
                            <i><strong><font color="red">(Format: PP 46/2011)</font></strong></i>
                          </div>
                        </template>

                        <template v-slot:item.indikator="{item}">
                          <div v-if="item.rencana_kinerja != null">
                            <br>
                            <div v-if="item.indikator_kuantitas != null">
                              <b>Indikator Kuantitas:</b> {{item.indikator_kuantitas}}
                            </div>
                            <div v-if="item.indikator_kualitas != null">
                              <b>Indikator Kualitas:</b> {{item.indikator_kualitas}}
                            </div>
                            <div v-if="item.indikator_waktu != null">
                              <b>Indikator Waktu:</b> {{item.indikator_waktu}}
                            </div>
                            <div v-if="item.indikator_bulanan != null">
                              <b>Indikator:</b> {{item.indikator_bulanan}}
                            </div>
                            <br>
                          </div>
                          <div v-else>
                            <br>
                            <div v-if="item.indikator_bulanan != null || item.indikator != null">
                              {{item.indikator_bulanan || item.indikator}}
                            </div>
                            <div v-if="item.indikator_kuantitas != null">
                              <b>Indikator Kuantitas:</b> {{item.indikator_kuantitas}}
                            </div>
                            <div v-if="item.indikator_kualitas != null">
                              <b>Indikator Kualitas:</b> {{item.indikator_kualitas}}
                            </div>
                            <div v-if="item.indikator_waktu != null">
                              <b>Indikator Waktu:</b> {{item.indikator_waktu}}
                            </div>
                            <br>
                          </div>
                        </template>

                        <template v-slot:item.target="{item}">
                          <div v-if="item.rencana_kinerja != null">
                            <div v-if="item.target_bawah != null">
                              <b>Target Kuantitas:</b> {{ item.target_bawah != null ? item.target_bawah : ''  }} {{ item.target_atas != null ? ' - '+item.target_atas : ''  }} {{ item.satuan_target }}<br><br>
                            </div>
                            <div v-if="item.kualitas_batas_bawah != null">
                              <b>Target Kualitas:</b> {{ item.kualitas_batas_bawah != null ? item.kualitas_batas_bawah : ''  }} {{ item.kualitas_batas_atas != null ? ' - '+item.kualitas_batas_atas : ''  }} %<br><br>
                            </div>
                            <div v-if="item.waktu_batas_bawah != null">
                              <b>Target Waktu:</b> {{ item.waktu_batas_bawah != null ? item.waktu_batas_bawah : ''  }} {{ item.waktu_batas_atas != null ? ' - '+item.waktu_batas_atas : ''  }} bulan
                            </div>
                            <div v-if="item.target != null">
                              <b>Target:</b> {{item.target + ' ' + item.satuan_target}}
                            </div>
                          </div>
                          <div v-else>
                            {{item.target + ' ' + item.satuan_target}}
                          </div>
                        </template>

                        <template v-slot:item.realisasi="{item}">
                          <div v-if="item.rencana_kinerja != null">
                            <br>
                            <div v-if="item.realisasi_kuantitas != null">
                              <b>Realisasi Kuantitas:</b> {{item.realisasi_kuantitas}} {{ item.satuan_target }}
                            </div>
                            <br>
                            <div v-if="item.indikator_kualitas != null">
                              <b>Realisasi Kualitas:</b> {{item.realisasi_kualitas}} %
                            </div>
                            <br>
                            <div v-if="item.indikator_waktu != null">
                              <b>Realisasi Waktu:</b> {{item.realisasi_waktu}} bulan
                            </div>
                            <div v-if="item.realisasi != null">
                              <b>Realisasi:</b> {{item.realisasi + ' ' + (item.satuan_realisasi != null ? item.satuan_realisasi : item.satuan_target)}}
                            </div>
                            <br>
                          </div>
                          <div v-else-if="item.realisasi != null">
                            {{item.realisasi + ' ' + (item.satuan_realisasi != null ? item.satuan_realisasi : item.satuan_target)}}
                          </div>
                        </template>


                        <!-- <template v-slot:item.target="{item}">
                            {{item.target}} {{item.satuan_target}}
                        </template>

                        <template v-slot:item.realisasi="{item}">
                            {{item.realisasi}} {{item.satuan_realisasi || item.satuan_target}}
                        </template> -->

                        <template v-slot:item.jenis="{item}">
                            <span v-if="triwulan === 'tahun' || triwulan === 'skpd'"><b>Tahunan</b></span>
                            <span v-else><b>Triwulan {{item.bulan}}</b></span>
                        </template>

                        <template v-slot:item.aksi="{item}">
                          <debug-btn :item="item"></debug-btn>
                          <div v-if="responseData.periode_validasi_skp_triwulan || responseData.periode_validasi_skp_tahunan">
                            <!-- <div v-if="item.bulan == triwulan_berjalan"> -->
                            <div v-if="responseData.periode_validasi_skp_tahunan || (item.bulan == (responseData.periode_validasi_skp_triwulan == false ? (triwulan_berjalan-1) : triwulan_berjalan))">
                              <confirm-btn
                                v-if="item.validasi == 4 && !hides.includes(item.id) && triwulan != 'skpd'"
                                title="Terima"
                                content="Apakah anda yakin <b>MENERIMA</b> laporan ini?"

                                :data="item"
                                :btnProps="{name:'Terima', color:'success', icon:'mdi-check', xSmall:true, class:{'ma-1':true} }"
                                :cardProps="{color:'success lighten-1'}"
                                @on-agree="terimaSKP"
                                class="ma-1"></confirm-btn>

                              <confirm-btn
                                v-if="item.validasi == 4 && !hides.includes(item.id) && triwulan != 'skpd'"
                                title="Tolak"
                                content="Apakah anda yakin <b>MENOLAK</b> laporan ini?"

                                :data="item"
                                :btnProps="{name:'Tolak', color:'error',icon:'mdi-close', xSmall:true, class:{'ma-1':true} }"
                                :cardProps="{color:'error lighten-1'}"
                                @on-agree="tolakSKP"
                                class="ma-1"></confirm-btn>
                            </div>
                            <!-- <div v-else-if="item.bulan == (responseData.periode_validasi_skp_triwulan == false ? (triwulan_berjalan-1) : triwulan_berjalan)">
                              <confirm-btn
                                v-if="item.validasi == 4 && !hides.includes(item.id)"
                                title="Terima"
                                content="Apakah anda yakin <b>MENERIMA</b> laporan ini?"

                                :data="item"
                                :btnProps="{name:'Terima', color:'success', icon:'mdi-check', xSmall:true, class:{'ma-1':true} }"
                                :cardProps="{color:'success lighten-1'}"
                                @on-agree="terimaSKP"
                                class="ma-1"></confirm-btn>

                              <confirm-btn
                                v-if="item.validasi == 4 && !hides.includes(item.id)"
                                title="Tolak"
                                content="Apakah anda yakin <b>MENOLAK</b> laporan ini?"

                                :data="item"
                                :btnProps="{name:'Tolak', color:'error',icon:'mdi-close', xSmall:true, class:{'ma-1':true} }"
                                :cardProps="{color:'error lighten-1'}"
                                @on-agree="tolakSKP"
                                class="ma-1"></confirm-btn>
                            </div> -->
                          </div>
                          <div v-else-if="responseData.buka_validasi_skp_tw_1_by_nip || responseData.buka_validasi_skp_tw_2_by_nip ||
                                    responseData.buka_validasi_skp_tw_3_by_nip || responseData.buka_validasi_skp_tw_4_by_nip || responseData.buka_validasi_skp_tahunan_by_nip">
                            <div>
                              <confirm-btn
                                v-if="item.validasi == 4 && !hides.includes(item.id)"
                                title="Terima"
                                content="Apakah anda yakin <b>MENERIMA</b> laporan ini?"

                                :data="item"
                                :btnProps="{name:'Terima', color:'success', icon:'mdi-check', xSmall:true, class:{'ma-1':true} }"
                                :cardProps="{color:'success lighten-1'}"
                                @on-agree="terimaSKP"
                                class="ma-1"></confirm-btn>

                              <confirm-btn
                                v-if="item.validasi == 4 && !hides.includes(item.id)"
                                title="Tolak"
                                content="Apakah anda yakin <b>MENOLAK</b> laporan ini?"

                                :data="item"
                                :btnProps="{name:'Tolak', color:'error',icon:'mdi-close', xSmall:true, class:{'ma-1':true} }"
                                :cardProps="{color:'error lighten-1'}"
                                @on-agree="tolakSKP"
                                class="ma-1"></confirm-btn>
                            </div>
                          </div>
                        </template>

                        <template v-slot:item.keterangan_validasi="{item}">
                          <div v-if="triwulan != 'skpd'">
                            <div class="green--text" v-if="item.validasi*1 === 1">{{item.keterangan_validasi}}</div>

                            <div class="red--text" v-else-if="item.validasi == 2">{{item.keterangan_validasi}}</div>

                            <div v-else>{{item.keterangan_validasi}}</div>
                          </div>

                          <v-btn class="ma-1" x-small color="info" @click="detailItem(item)"><v-icon x-small left>mdi-magnify</v-icon> Lihat detail {{triwulan != 'skpd' ? triwulan != 'tahun' ? 'Triwulan '+ triwulan : 'Tahunan' : 'SKP'}} ini</v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <v-divider v-if="peg.skp.length > 0"></v-divider>
                </div>
                <div v-else>
                  <v-row class="mt-5">
                    <v-col class="d-flex justify-center">
                      <v-avatar size="80">
                        <v-img :src="peg.foto" :alt="peg.username"></v-img>
                      </v-avatar>
                      <div
                        style="max-width:350px"
                      >
                        <v-list-item three-line>
                          <v-list-item-content>
                            <v-list-item-title class="headline mb-1">
                              {{peg.nama}}
                            </v-list-item-title>
                            <v-list-item-subtitle>NIP. {{peg.nip}}</v-list-item-subtitle>
                            <div class="mb-1">
                              {{peg.jabatan}}
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row v-if="peg.semua_skp.length > 0">
                    <v-col>
                      <v-data-table :headers="headers_verifikasi" :items="peg.semua_skp" hide-default-footer :items-per-page="peg.semua_skp.length">
                        <!-- <template v-slot:item.jenis>
                          {{triwulan == "0" ? 'Tahunan' : 'Triwulan' + triwulan}}
                        </template> -->

                        <template v-slot:item.sasaran="{item}">
                          <div v-if="item.rencana_kinerja != null">
                            {{ item.rencana_kinerja }}<br>
                            <i v-if="year >= 2022"><strong><font color="black">(Format: Permenpan No. 6/2022)</font></strong></i>
                            <i v-else><strong><font color="black">(Format: Permenpan No. 8/2021)</font></strong></i>
                          </div>
                          <div v-else>
                            {{item.sasaran || item.sasaran_kinerja_tahunan || item.rencana_kinerja}}
                            <i><strong><font color="red">(Format: PP 46/2011)</font></strong></i>
                          </div>
                        </template>

                        <template v-slot:item.indikator="{item}">
                          <div v-if="item.rencana_kinerja != null">
                            <br>
                            <div v-if="item.indikator_kuantitas != null">
                              <b>Indikator Kuantitas:</b> {{item.indikator_kuantitas}}
                            </div>
                            <div v-if="item.indikator_kualitas != null">
                              <b>Indikator Kualitas:</b> {{item.indikator_kualitas}}
                            </div>
                            <div v-if="item.indikator_waktu != null">
                              <b>Indikator Waktu:</b> {{item.indikator_waktu}}
                            </div>
                            <div v-if="item.indikator_bulanan != null">
                              <b>Indikator:</b> {{item.indikator_bulanan}}
                            </div>
                            <br>
                          </div>
                          <div v-else>
                            <br>
                            <div v-if="item.indikator_bulanan != null || item.indikator != null">
                              {{item.indikator_bulanan || item.indikator}}
                            </div>
                            <div v-if="item.indikator_kuantitas != null">
                              <b>Indikator Kuantitas:</b> {{item.indikator_kuantitas}}
                            </div>
                            <div v-if="item.indikator_kualitas != null">
                              <b>Indikator Kualitas:</b> {{item.indikator_kualitas}}
                            </div>
                            <div v-if="item.indikator_waktu != null">
                              <b>Indikator Waktu:</b> {{item.indikator_waktu}}
                            </div>
                            <br>
                          </div>
                        </template>

                        <template v-slot:item.target="{item}">
                          <div v-if="item.rencana_kinerja != null">
                            <div v-if="item.target_bawah != null">
                              <b>Target Kuantitas:</b> {{ item.target_bawah != null ? item.target_bawah : ''  }} {{ item.target_atas != null ? ' - '+item.target_atas : ''  }} {{ item.satuan_target }}<br><br>
                            </div>
                            <div v-if="item.kualitas_batas_bawah != null">
                              <b>Target Kualitas:</b> {{ item.kualitas_batas_bawah != null ? item.kualitas_batas_bawah : ''  }} {{ item.kualitas_batas_atas != null ? ' - '+item.kualitas_batas_atas : ''  }} %<br><br>
                            </div>
                            <div v-if="item.waktu_batas_bawah != null">
                              <b>Target Waktu:</b> {{ item.waktu_batas_bawah != null ? item.waktu_batas_bawah : ''  }} {{ item.waktu_batas_atas != null ? ' - '+item.waktu_batas_atas : ''  }} bulan
                            </div>
                            <div v-if="item.target != null">
                              <b>Target:</b> {{item.target + ' ' + item.satuan_target}}
                            </div>
                          </div>
                          <div v-else>
                            {{item.target + ' ' + item.satuan_target}}
                          </div>
                        </template>

                        <template v-slot:item.jenis="{item}">
                          <span><b>{{item.jenis}}</b></span>
                        </template>

                        <template v-slot:item.aksi="{item}">
                          <debug-btn :item="item"></debug-btn>
                          <div v-if="responseData.periode_verifikasi_skp_triwulan || responseData.periode_verifikasi_skp_tahunan || responseData.is_nip_listed_verifikasi_skp">
                              <confirm-btn
                                v-if="item.verifikasi == null || item.verifikasi == false"
                                title="Terima"
                                content="Apakah anda yakin <b>MENERIMA</b> verifikasi SKP ini?"

                                :data="item"
                                :btnProps="{name:'Terima', color:'success', icon:'mdi-check', xSmall:true, class:{'ma-1':true} }"
                                :cardProps="{color:'success lighten-1'}"
                                @on-agree="terimaVerifikasiSKP"
                                class="ma-1"></confirm-btn>

                              <confirm-btn
                                v-if="item.verifikasi == null || item.verifikasi == false"
                                title="Tolak"
                                content="Apakah anda yakin <b>MENOLAK</b> verifikasi SKP ini?"

                                :data="item"
                                :btnProps="{name:'Tolak', color:'error',icon:'mdi-close', xSmall:true, class:{'ma-1':true} }"
                                :cardProps="{color:'error lighten-1'}"
                                @on-agree="tolakVerifikasiSKP"
                                class="ma-1"></confirm-btn>
                          </div>
                        </template>

                        <template v-slot:item.verifikasi="{item}">
                          <div class="warning--text" v-if="item.verifikasi === null">Belum Diverifikasi</div>
                          <div class="green--text" v-else-if="item.verifikasi == true">Verifikasi Diterima</div>
                          <div class="red--text" v-else-if="item.verifikasi == false">Verifikasi Ditolak</div>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>

                  <v-divider v-if="peg.semua_skp.length > 0"></v-divider>

                </div>
              </div>
            </v-col>

            <v-col v-else class="d-flex justify-center mb-10">
              <v-progress-circular
                indeterminate
                color="primary"
                v-if="isLoading"
              ></v-progress-circular>
            </v-col>

          </v-row>
        </base-material-card>
      </v-col>

    </v-row>

    <v-dialog v-model="dialogValidasi" max-width="600">
      <v-card>
        <v-card-title v-if="triwulan == 'tahun'"><h1 class="display-2">SKP Tahunan Nilai</h1></v-card-title>
        <v-card-title v-else><h1 class="display-2">Validasi SKP Triwulan</h1></v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-form ref="form" v-model="valid">
          <div v-if="triwulan == 'tahun' && year <= 2021">
            <h1 class="display-1 text-center">Standar Nilai Prestasi Kinerja</h1>
            <v-divider></v-divider>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Penilaian</th>
                  <th>Keterangan</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>100</td>
                  <td>Sangat Berhasil</td>
                </tr>
                <tr>
                  <td>90-99</td>
                  <td>Berhasil</td>
                </tr>
                <tr>
                  <td>60-89</td>
                  <td>Kurang Berhasil</td>
                </tr>
                <tr>
                  <td>&lt;60</td>
                  <td>Tidak Berhasil</td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <v-divider v-if="year <= 2021"></v-divider>

          <div v-if="formValidasi.format_skp == 'baru'">
            <v-row v-if="formValidasi.target_bawah != null">
              <v-col>
                <div class="my-2">
                  <h3 v-if="formValidasi.target_bawah != null">
                    Target Kuantitas: {{ formValidasi.target_bawah != null ? formValidasi.target_bawah : ''  }} {{ formValidasi.target_atas != null ? ' - '+formValidasi.target_atas : ''  }} {{ formValidasi.satuan_target }}
                  </h3>
                </div>
              </v-col>
              <v-col>
                <div class="my-2">
                  <h3 class="my-1 success--text" v-if="formValidasi.realisasi_kuantitas != null"><b>Realisasi Kuantitas:</b> {{ formValidasi.realisasi_kuantitas+ ' ' +formValidasi.satuan_target }}</h3>
                </div>
              </v-col>
              <v-col>
                <div class="my-2">
                  <h3 class="label font-weight-light">Capaian</h3>
                  <div class="font-weight-regular">{{formValidasi.capaian || "-"}} % </div>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="formValidasi.kualitas_batas_bawah != null && year <= 2021">
              <v-col>
                <div class="my-2">
                  <h3 v-if="formValidasi.kualitas_batas_bawah != null">
                    Target Kualitas: {{ formValidasi.kualitas_batas_bawah != null ? formValidasi.kualitas_batas_bawah : ''  }} {{ formValidasi.kualitas_batas_atas != null ? ' - '+formValidasi.kualitas_batas_atas : ''  }} %
                  </h3>
                </div>
              </v-col>
              <v-col>
                <div class="my-2">
                  <h3 class="my-1 success--text" v-if="formValidasi.realisasi_kualitas != null"><b>Realisasi Kualitas:</b> {{ formValidasi.realisasi_kualitas+ ' %' }}</h3>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="formValidasi.waktu_batas_bawah != null && year <= 2021">
              <v-col>
                <div class="my-2">
                  <h3 v-if="formValidasi.waktu_batas_bawah != null">
                    Target Waktu: {{ formValidasi.waktu_batas_bawah != null ? formValidasi.waktu_batas_bawah : ''  }} {{ formValidasi.waktu_batas_atas != null ? ' - '+formValidasi.waktu_batas_atas : ''  }} bulan
                  </h3>
                </div>
              </v-col>
              <v-col>
                <div class="my-2">
                  <h3 class="my-1 success--text" v-if="formValidasi.realisasi_waktu != null"><b>Realisasi Waktu:</b> {{ formValidasi.realisasi_waktu+ ' bulan' }}</h3>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="formValidasi.target != null && formValidasi.realisasi != null">
              <v-col>
                <div class="my-2">
                  <h3 class="label font-weight-light">Target</h3>
                  <div class="font-weight-regular">{{formValidasi.target || "-"}} {{formValidasi.satuan_target}}</div>
                </div>
              </v-col>
              <v-col>
                <div class="my-2">
                  <h3 class="label font-weight-light">Realisasi</h3>
                  <div class="font-weight-regular">{{formValidasi.realisasi || "-"}} {{formValidasi.satuan_target}}</div>
                </div>
              </v-col>
              <v-col>
                <div class="my-2">
                  <h3 class="label font-weight-light">Capaian</h3>
                  <div class="font-weight-regular">{{formValidasi.capaian || "-"}} % </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row>
              <v-col>
                <div class="my-2">
                  <h3 class="label font-weight-light">Target</h3>
                  <p class="font-weight-regular">{{formValidasi.target || "-"}} {{formValidasi.satuan_target}}</p>
                </div>
              </v-col>
              <v-col>
                <div class="my-2">
                  <h3 class="label font-weight-light">Realisasi</h3>
                  <p class="font-weight-regular">{{formValidasi.realisasi || "-"}} {{formValidasi.satuan_target}}</p>
                </div>
              </v-col>
            </v-row>
          </div>

          <v-row v-if="year >= 2023">
            <v-col>
              <div>
                <span class="label font-weight-light">Rating</span>
                <div class="d-flex align-center">
                  <v-rating
                    v-model="formValidasi.rating"
                    background-color="orange lighten-3"
                    color="orange"
                    size="30"
                    hover
                  ></v-rating>
                  <span>{{formValidasi.rating || 0}} / 5</span>
                </div>
              </div>
            </v-col>
          <!-- </v-row> -->
          <!-- <v-row v-if="year >= 2023"> -->
            <v-col class="d-flex flex-column">
              <div>
                <span class="label font-weight-light">{{rangeNilai(formValidasi.rating)}}</span>
              </div>
              <div>
                <v-text-field dense v-model="formValidasi.kualitas_mutu" :disabled="!formValidasi.rating ? true : false" :rules="[rules.required, rules.onlyInt, rules.min(this.min_rate, formValidasi.kualitas_mutu), rules.max(this.max_rate, formValidasi.kualitas_mutu)]" outlined label="Nilai"></v-text-field>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="year >= 2023">
            <v-col>
              <div>
                <!-- <h3 class="label font-weight-light">Rating Hasil Kerja Periodik</h3> -->
                <h1 class="display-2 my-2">Rating Hasil Kerja Periodik</h1>
                <p>Pejabat Penilai Kinerja melihat kembali realisasi beserta data dukung yang relevan untuk setiap hasil kerja dan menetapkan rating hasil kerja pegawai dalam kategori:</p>
                <v-radio-group v-model="formValidasi.bobot" :disabled="!formValidasi.rating ? true : false" column :rules="[rules.required]" @change="openDialogKeterangan">
                  <v-radio color="red" value="1" :disabled="di_bawah_ekspektasi">
                    <template v-slot:label>
                      <strong class="red--text">Di bawah Ekspektasi</strong>
                    </template>
                  </v-radio>
                  <v-radio color="primary" value="2" :disabled="sesuai_ekspektasi">
                    <template v-slot:label>
                      <strong class="primary--text">Sesuai Ekspektasi</strong>
                    </template>
                  </v-radio>
                  <v-radio color="success" value="3" :disabled="di_atas_ekspektasi">
                    <template v-slot:label>
                      <strong class="success--text">Di atas Ekspektasi</strong>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>
          <v-row v-else-if="year >= 2022">
            <v-col>
              <div>
                <h3 class="label font-weight-light">Rating Hasil Kerja Periodik</h3>
                <p>Pejabat Penilai Kinerja melihat kembali realisasi beserta data dukung yang relevan untuk setiap hasil kerja dan menetapkan rating hasil kerja pegawai dalam kategori:</p>
                <v-radio-group v-model="formValidasi.bobot" column :rules="[rules.required]" @change="openDialogKeterangan">
                  <v-radio color="red" value="1" :disabled="di_bawah_ekspektasi">
                    <template v-slot:label>
                      <strong class="red--text">Di bawah Ekspektasi</strong>
                    </template>
                  </v-radio>
                  <v-radio color="primary" value="2" :disabled="sesuai_ekspektasi">
                    <template v-slot:label>
                      <strong class="primary--text">Sesuai Ekspektasi</strong>
                    </template>
                  </v-radio>
                  <v-radio color="success" value="3" :disabled="di_atas_ekspektasi">
                    <template v-slot:label>
                      <strong class="success--text">Di atas Ekspektasi</strong>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <div>
                <span class="label font-weight-light">Kualitas Mutu</span>
                <div class="d-flex align-center">
                  <v-rating
                    v-model="formValidasi.rating"
                    background-color="orange lighten-3"
                    color="orange"
                    size="30"
                    hover
                  ></v-rating>
                  <span>{{formValidasi.rating || 0}} / 5</span>
                </div>
              </div>
            </v-col>
            <v-col>
              <div>
                <span class="label font-weight-light">Keterangan</span>
                <div class="font-weight-regular">{{keteranganRating(formValidasi.rating)}}</div>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="year <= 2021">
            <v-col>
              <div>
                <span class="label font-weight-light">{{rangeNilai(formValidasi.rating)}}</span>
              </div>
            </v-col>
            <v-text-field dense v-model="formValidasi.kualitas_mutu" :rules="[rules.required, rules.onlyInt, rules.min(this.min_rate, formValidasi.kualitas_mutu), rules.max(this.max_rate, formValidasi.kualitas_mutu)]" outlined label="Nilai"></v-text-field>
          </v-row>

          <v-divider></v-divider>
          <h1 class="display-2 my-2">Laporan Data Dan Pengukuran Kinerja</h1>

          <div class="my-2">
            <span class="label font-weight-light">Formulasi Pengukuran, Alasan, dan Kriteria</span>
            <div class="font-weight-regular">{{formValidasi.formulasi || '-'}}</div>
          </div>

          <v-textarea v-model="formValidasi.data_relevan"  outlined label="Data Yang Relevan Dengan Target Kinerja Triwulan" disabled></v-textarea>

          <v-textarea v-model="formValidasi.pengukuran_kinerja"  outlined label="Pengukuran Kinerja" disabled></v-textarea>

          <div v-if="year >= 2022">
            <!-- <v-textarea v-model="debounceEvaluasiUmpanBalik"  outlined label="Evaluasi/Umpan Balik Berdasarkan Bukti Dukung" required hint="Wajib Diisi ! Minimal 30 karakter" :rules="[rules.required, rules.min30]"></v-textarea> -->
            <textarea-emoji-picker v-model="formValidasi.umpan_balik" :label="'Evaluasi/Umpan Balik Berdasarkan Bukti Dukung'" />
            <!-- <textarea-emoji-picker v-model="formValidasi.umpan_balik_feedback" :label="'Feedback atas Evaluasi/Umpan Balik Berdasarkan Bukti Dukung'"/> -->
            <v-textarea v-model="debounceEvaluasiUmpanBalikFeedback" outlined label="Feedback atas Evaluasi/Umpan Balik Berdasarkan Bukti Dukung" disabled></v-textarea>
          </div>
          <div v-else>
            <v-textarea v-model="debounceEvaluasiAnalisisKinerja"  outlined label="Evaluasi/Arahan Pimpinan" required hint="Wajib Diisi ! Minimal 30 karakter" :rules="[rules.required, rules.min30]"></v-textarea>
          </div>

          <div class="my-2">
            <span class="label font-weight-light"><i>Evidence</i> / Bukti Laporan</span>
            <div v-if="!formValidasi.file" class="font-weight-regular">-</div>
            <div v-else>
              <br/>
              <template v-for="(f,i) in parseFilename(formValidasi.file)">
                <div v-bind:key="i">Evidence ke-{{i+1}} : <a :href="triwulan == 'tahun' ? urlEvidence(f) : urlEvidenceTw(f)" target="_blank">{{(typeof f.name !== 'undefined' ? f.name : f)}}</a></div>
              </template>
            </div>
          </div>

          <div class="my-2" v-if="triwulan == 'tahun'">
            <span class="label font-weight-light">Aktivitas Terkait SKP</span>
            <div v-if="!formValidasi.aktivitas_terkait_skp || formValidasi.aktivitas_terkait_skp.length == 0" class="font-weight-regular">-</div>
            <div v-else class="font-weight-regular" v-for="(f,i) in formValidasi.aktivitas_terkait_skp" :key="i">{{f}}</div>
          </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog()">Tutup</v-btn>
          <v-btn @click="kirimTerimaSKP()" color="success" :loading="loadingBtn.validasi">Validasi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTerimaVerifikasi" max-width="600">
      <v-card>
        <v-card-title><h1 class="display-2">Terima Verifikasi Sasaran Kinerja Pegawai {{ formVerifikasi.jenis == 'tahun' ? 'Tahunan' : 'Triwulan '+ formVerifikasi.jenis }}</h1></v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-row v-if="dialogTerimaVerifikasi == true">
            <b>Rencana/Sasaran Kinerja {{ formVerifikasi.jenis == 'tahun' ? 'Tahunan' : 'Triwulan '+ formVerifikasi.jenis }} :</b> <p>{{formVerifikasi.rencana_kinerja}}</p>
          </v-row>
          <v-row v-if="dialogTerimaVerifikasi == true">
            <div v-if="formVerifikasi.rencana_kinerja != null">
              <div v-if="formVerifikasi.indikator_kuantitas != null">
                <b>Indikator Kuantitas :</b> <p>{{formVerifikasi.indikator_kuantitas}}</p>
              </div>
              <div v-if="formVerifikasi.indikator_kualitas != null">
                <b>Indikator Kualitas :</b> <p>{{formVerifikasi.indikator_kualitas}}</p>
              </div>
              <div v-if="formVerifikasi.indikator_waktu != null">
                <b>Indikator Waktu :</b> <p>{{formVerifikasi.indikator_waktu}}</p>
              </div>
              <div v-if="formVerifikasi.indikator_bulanan != null">
                <b>Indikator :</b> <p>{{formVerifikasi.indikator_bulanan}}</p>
              </div>
              <br>
            </div>
            <div v-else>
              <div v-if="formVerifikasi.indikator_bulanan != null || formVerifikasi.indikator != null">
                {{formVerifikasi.indikator_bulanan || formVerifikasi.indikator}}
              </div>
              <div v-if="formVerifikasi.indikator_kuantitas != null">
                <b>Indikator Kuantitas :</b> <p>{{formVerifikasi.indikator_kuantitas}}</p>
              </div>
              <div v-if="formVerifikasi.indikator_kualitas != null">
                <b>Indikator Kualitas :</b> <p>{{formVerifikasi.indikator_kualitas}}</p>
              </div>
              <div v-if="formVerifikasi.indikator_waktu != null">
                <b>Indikator Waktu :</b> <p>{{formVerifikasi.indikator_waktu}}</p>
              </div>
              <br>
            </div>
          </v-row>
          <v-textarea v-model="debounceTerimaKeterangan" outlined label="keterangan"></v-textarea>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click='dialogTerimaVerifikasi=false'>Tutup</v-btn>
          <v-btn color="success" @click="kirimTerimaVerifikasi()" :loading="loadingBtn.terima">Terima</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTolakVerifikasi" max-width="600">
      <v-card>
        <v-card-title><h1 class="display-2">Tolak Verifikasi Sasaran Kinerja Pegawai {{ formVerifikasi.jenis == 'tahun' ? 'Tahunan' : 'Triwulan '+ formVerifikasi.jenis }}</h1></v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <b>Rencana/Sasaran Kinerja {{ formVerifikasi.jenis == 'tahun' ? 'Tahunan' : 'Triwulan '+ formVerifikasi.jenis }} :</b> <p>{{formVerifikasi.rencana_kinerja}}</p>
          </v-row>
          <v-row>
            <div v-if="formVerifikasi.rencana_kinerja != null">
              <div v-if="formVerifikasi.indikator_kuantitas != null">
                <b>Indikator Kuantitas :</b> <p>{{formVerifikasi.indikator_kuantitas}}</p>
              </div>
              <div v-if="formVerifikasi.indikator_kualitas != null">
                <b>Indikator Kualitas :</b> <p>{{formVerifikasi.indikator_kualitas}}</p>
              </div>
              <div v-if="formVerifikasi.indikator_waktu != null">
                <b>Indikator Waktu :</b> <p>{{formVerifikasi.indikator_waktu}}</p>
              </div>
              <div v-if="formVerifikasi.indikator_bulanan != null">
                <b>Indikator :</b> <p>{{formVerifikasi.indikator_bulanan}}</p>
              </div>
              <br>
            </div>
            <div v-else>
              <div v-if="formVerifikasi.indikator_bulanan != null || formVerifikasi.indikator != null">
                {{formVerifikasi.indikator_bulanan || formVerifikasi.indikator}}
              </div>
              <div v-if="formVerifikasi.indikator_kuantitas != null">
                <b>Indikator Kuantitas :</b> <p>{{formVerifikasi.indikator_kuantitas}}</p>
              </div>
              <div v-if="formVerifikasi.indikator_kualitas != null">
                <b>Indikator Kualitas :</b> <p>{{formVerifikasi.indikator_kualitas}}</p>
              </div>
              <div v-if="formVerifikasi.indikator_waktu != null">
                <b>Indikator Waktu :</b> <p>{{formVerifikasi.indikator_waktu}}</p>
              </div>
              <br>
            </div>
          </v-row>
          <v-textarea v-model="debounceTolakKeterangan" outlined label="keterangan"></v-textarea>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click='dialogTolakVerifikasi=false'>Tutup</v-btn>
          <v-btn color="error" @click="kirimTolakVerifikasi()" :loading="loadingBtn.tolak">Tolak</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTolak" max-width="600">
      <v-card>
        <v-card-title><h1 class="display-2">Tolak Sasaran Kinerja Pegawai</h1></v-card-title>
        <v-divider></v-divider>

        <v-card-text><v-textarea v-model="debounceTolakKeterangan" outlined label="keterangan"></v-textarea></v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click='dialogTolak=false'>Tutup</v-btn>
          <v-btn color="error" @click="kirimTolakSKP()" :loading="loadingBtn.tolak">Tolak</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDetail" max-width="600">
      <v-card>
        <v-card-title>
          <h1 class="display-2 my-2">Detail Sasaran Kinerja Pegawai {{triwulan_text}}</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="my-2">
            <span class="label font-weight-light">Jenis</span>
            <div class="font-weight-regular">{{triwulan_text}}</div>
          </div>
          <div class="my-2">
            <span class="label font-weight-light">Sasaran Strategis</span>
            <div class="font-weight-regular">{{defaultStrip(formDetail.sasaran_strategis)}}</div>
          </div>
          <v-divider></v-divider>

          <h1 class="display-2 my-2">Indikator Kinerja</h1>
          <div class="my-2">
            <span class="label font-weight-light">Jenis SKP</span>
            <div class="font-weight-regular" >SKP</div>
          </div>
          <div class="my-2">
            <span class="label font-weight-light">Indikator Kegiatan/ Output/ Keluaran</span>
            <div class="font-weight-regular">{{defaultStrip(formDetail.indikator)}}</div>
          </div>

          <v-row>
            <v-col>
                <span class="label font-weight-light">Target</span>
                <div class="font-weight-regular">{{defaultStrip(formDetail.target)}} {{formDetail.satuan_target}}</div>
            </v-col>
            <v-col>
                <span class="label font-weight-light">Realisasi</span>
                <div class="font-weight-regular">{{defaultStrip(formDetail.realisasi)}} {{formDetail.satuan_target}}</div>
            </v-col>
          </v-row>

          <div class="my-2">
            <span class="label font-weight-light">Capaian</span>
            <div class="font-weight-regular">{{defaultStrip(formDetail.capaian)}}</div>
          </div>

          <v-row>
            <v-col>
                <span class="label font-weight-light">Nilai Pembalik</span>
                <div class="font-weight-regular">{{defaultStrip(formDetail.is_pembalik ? 'Ya':'Tidak')}}</div>
            </v-col>
            <v-col v-if="year <= 2021">
                <span class="label font-weight-light">Waktu</span>
                <div class="font-weight-regular">{{defaultStrip(formDetail.waktu)}} {{formDetail.satuan_waktu}}</div>
            </v-col>
            <v-col v-if="year <= 2021">
                <span class="label font-weight-light">Kualitas</span>
                <div class="font-weight-regular">{{defaultStrip(formDetail.kualitas)}} %</div>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <h1 class="display-2 my-2">Laporan Data, Pengukuran Kinerja, Evaluasi/Arahan Pimpinan</h1>

          <div class="my-2">
            <span class="label font-weight-light">Formulasi Pengukuran, Alasan, dan Kriteria</span>
            <div class="font-weight-regular">{{defaultStrip(formDetail.formulasi_pengukuran)}}</div>
          </div>

          <div class="my-2">
            <span class="label font-weight-light">Data Yang Relevan Dengan Target Kinerja {{triwulan_text}}</span>
            <div class="font-weight-regular">{{defaultStrip(formDetail.data_relevan)}}</div>
          </div>

          <div class="my-2">
            <span class="label font-weight-light">Pengukuran Kinerja</span>
            <div class="font-weight-regular">{{defaultStrip(formDetail.pengukuran_kinerja)}}</div>
          </div>

          <div class="my-2">
            <span class="label font-weight-light">Evaluasi Analisis Kinerja</span>
            <div class="font-weight-regular">{{defaultStrip(formDetail.evaluasi_analisis_kinerja ? formDetail.evaluasi_analisis_kinerja : formDetail.umpan_balik)}}</div>
          </div>

          <div class="my-2">
            <span class="label font-weight-light">FeedBack Evaluasi Analisis Kinerja</span>
            <div class="font-weight-regular">{{defaultStrip(formDetail.feedback_evaluasi_analisis_kinerja ? formDetail.feedback_evaluasi_analisis_kinerja : formDetail.umpan_balik_feedback)}}</div>
          </div>

          <div class="my-2">
            <span class="label font-weight-light"><i>Evidence</i> / Bukti Laporan</span>
            <!-- <div class="font-weight-regular">{{formDetail.file || '-'}}</div> -->
            <div v-if="parseFile === '-'">{{parseFile}}</div>
            <div v-else class="d-flex flex-column">
              <debug-btn :item="parseFile"></debug-btn>
              <br>
              <template v-for="(f,i) in parseFile">
                <div v-bind:key="i">Evidence ke-{{i+1}} : <a :href="triwulan == 'tahun' ? urlEvidence(f) : urlEvidenceTw(f)" target="_blank">{{(typeof f.name !== 'undefined' ? f.name : f)}}</a></div>
              </template>
            </div>
          </div>

          <!-- <v-divider></v-divider>
          <h1 class="display-2 my-2">Indikator Keuangan</h1>
          <div class="my-2">
            <span class="label font-weight-light">Kegiatan</span>
            <div class="font-weight-regular">{{defaultStrip(formDetail.kegiatan)}}</div>
          </div>
          <div class="my-2">
            <span class="label font-weight-light">Pagu</span>
            <div class="font-weight-regular">{{defaultStrip(formDetail.pagu)}}</div>
          </div>
          <div class="my-2">
            <span class="label font-weight-light">Realisasi</span>
            <div class="font-weight-regular">{{defaultStrip(formDetail.realisasi_pagu)}}</div>
          </div> -->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogDetail=false">Tutup</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="year >= 2023" v-model="dialogKeteranganJawaban" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Masukan alasan anda untuk penilaian tersebut:</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formKeteranganJawaban">
            <v-row align="center">
              <v-col cols="12" align="center">
                <v-textarea @copy.prevent @paste="onPasteKeterangan" @paste.prevent @cut.prevent @drag.prevent @drop.prevent :disabled="disabledSimpanKeterangan" counter v-model="debounceKeteranganBobot" :rules="[rules.required, v => (v && v.length >= 200) || 'Minimal 200 karakter']" label="Keterangan" outlined auto-grow></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="btn-simpan-keterangan" color="success" :disabled="disabledSimpanKeterangan" @click="simpanKeteranganJawaban()">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogKinerjaOrganisasi" max-width="1200">
      <v-card>
        <v-card-title><h1 class="display-2">Penilaian Capaian Kinerja Organisasi (Komponen Proses)</h1></v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-form ref="formKinerjaOrganisasi">
          <div class="d-flex">
            <div style="width:500px !important"><b>Rencana Kinerja</b></div>
            <div style="width:500px !important"><b>Indikator</b></div>
            <div style="width:100px !important"><b>Target</b></div>
            <div style="width:100px !important"><b>Realisasi</b></div>
          </div>
          <div v-for="(d,i) in pegawai.skp" :key="i" class="d-flex">
            <div style="width:500px !important">
              <div v-if="d.rencana_kinerja != null">
                {{ d.rencana_kinerja }}<br>
                <!-- <i v-if="year >= 2022"><strong><font color="black">(Format: Permenpan No. 6/2022)</font></strong></i>
                <i v-else><strong><font color="black">(Format: Permenpan No. 8/2021)</font></strong></i> -->
              </div>
              <div v-else>
                {{d.sasaran || d.sasaran_kinerja_tahunan || d.rencana_kinerja}}
                <!-- <i><strong><font color="red">(Format: PP 46/2011)</font></strong></i> -->
              </div>
            </div>
            <div style="width:500px !important">
              <div v-if="d.indikator_kuantitas != null">
                <b>Indikator Kuantitas:</b> {{d.indikator_kuantitas}}
              </div>
              <div v-if="d.indikator_kualitas != null">
                <b>Indikator Kualitas:</b> {{d.indikator_kualitas}}
              </div>
              <div v-if="d.indikator_waktu != null">
                <b>Indikator Waktu:</b> {{d.indikator_waktu}}
              </div>
              <div v-if="d.indikator_bulanan != null">
                <b>Indikator:</b> {{d.indikator_bulanan}}
              </div>
            </div>
            <div style="width:100px !important">
              <div v-if="d.target_bawah != null">{{ d.target_bawah }}&nbsp;{{ d.satuan_target }}</div>
            </div>
            <div style="width:100px !important">
              <div v-if="d.realisasi_kuantitas != null">{{ d.realisasi_kuantitas }}&nbsp;{{ d.satuan_target }}</div>
            </div>
          </div>

          <br><br>

          <v-row v-if="year >= 2023">
            <v-col cols="3">
              <div>
                <span class="label font-weight-light"><b>Kualitas Capaian Kinerja Organisasi</b></span>
                <div class="d-flex align-center">
                  <v-rating
                    v-model="formKinerjaOrganisasi.rating"
                    background-color="orange lighten-3"
                    color="orange"
                    size="30"
                    hover
                  ></v-rating>
                  <span>{{formKinerjaOrganisasi.rating || 0}} / 5</span>
                </div>
              </div>
            </v-col>
          <!-- </v-row> -->
          <!-- <v-row v-if="year >= 2023"> -->
            <v-col class="d-flex flex-column" cols="9">
              <div>
                <span class="label font-weight-light">{{rangeNilai(formKinerjaOrganisasi.rating)}}</span>
              </div>
              <div>
                <v-text-field dense v-model="formKinerjaOrganisasi.kualitas_mutu" :disabled="!formKinerjaOrganisasi.rating ? true : false" :rules="[rules.required, rules.onlyInt, rules.min(this.min_rate, formKinerjaOrganisasi.kualitas_mutu), rules.max(this.max_rate, formKinerjaOrganisasi.kualitas_mutu)]" outlined label="Nilai"></v-text-field>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="year >= 2023">
            <v-col>
              <div>
                <!-- <h3 class="label font-weight-light">Rating Hasil Kerja Periodik</h3> -->
                <h1 class="display-2 my-2">Penetapan Komponen Proses Kinerja Organisasi</h1>
                <p>Pejabat Penilai Kinerja melihat kembali upaya perubahan proses atau inovasi yang akan berdampak pada peningkatan hasil kerja dalam kategori:</p>
                <v-radio-group v-model="formKinerjaOrganisasi.bobot" :disabled="!formKinerjaOrganisasi.rating ? true : false" column :rules="[rules.required]" @change="openDialogKeterangan">
                  <v-radio color="red" value="1">
                    <template v-slot:label>
                      <strong class="red--text">Di bawah Ekspektasi</strong>&nbsp;<label color="black"><strong>Apabila tidak ada upaya perbaikan proses untuk meningkatkan hasil kerja (status quo)</strong></label>
                    </template>
                  </v-radio>
                  <v-radio color="primary" value="2">
                    <template v-slot:label>
                      <strong class="primary--text">Sesuai Ekspektasi</strong>&nbsp;<label color="black"><strong>Apabila terdapat upaya perbaikan proses yang akan berdampak pada peningkatan hasil kerja secara inkremental</strong></label>
                    </template>
                  </v-radio>
                  <v-radio color="success" value="3">
                    <template v-slot:label>
                      <strong class="success--text">Di atas Ekspektasi</strong>&nbsp;<label color="black"><strong>Apabila terdapat upaya perubahan proses atau inovasi (distruptive innovation) yang akan berdampak pada peningkatan hasil kerja secara radikal</strong></label>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>

          <textarea-emoji-picker v-model="formKinerjaOrganisasi.evaluasi" :label="'Evaluasi'" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialogKinerjaOrganisasi()">Tutup</v-btn>
          <v-btn @click="submitNilaiKinerjaOrganisasi()" color="success" :loading="loadingBtn.validasi">Submit Nilai</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import _ from 'lodash'
import store from '@/store'
import SKPService from '@/services/SKPService'
import DebugBtn from '@/components/common/DebugBtn'
import ConfirmBtn from '@/components/base/ConfirmBtn'
import {defaultRules} from '@/utils/lib'
import TextareaEmojiPicker from './components/TextareaEmojiPicker'
import _g from '@/global'
import axios from 'axios'
export default {
  components:{
    ConfirmBtn,
    DebugBtn,
    TextareaEmojiPicker
  },

  data(){
    return{
      valid:true,
      title:'Validasi SKP',
      forceUpdate:false,
      formDetail:{},
      formValidasi:{},
      formTolakKeterangan:"",
      idTolak:null,
      loading:false,
      detailData:{},
      triwulan:1,
      hides:[],
      dialogDetail:false,
      dialogValidasi:false,
      dialogTolak:false,
      selectedItem:{},
      tabItems:['Triwulan I', 'Triwulan II', 'Triwulan III', 'Triwulan IV', 'Tahunan'],
      tableValues:{},
      loadingBtn:{
        terima:false,
        tolak:false,
        validasi:false,
      },
      ratingList:['kurang', 'kurang berhasil', 'cukup', 'berhasil', 'sangat berhasil'],
      rules:{
        ...defaultRules,
        min30: v=> (v && v.length > 30) || 'Minimal 30 karakter'
      },

      headers:[
        {text:'Rencana/Sasaran Kinerja', value:'sasaran', sortable:false, class:'header-index-profile', width:'42%'},
        {text:'Indikator Kinerja', value:'indikator', sortable:false, class:'header-index-profile', width:'35%'},
        {text:'Jenis SKP', value:'jenis', sortable:false, class:'header-index-profile', width:'20%'},
        {text:'Target Kinerja', value:'target', sortable:false, class:'header-index-profile'},
        {text:'Realisasi Kinerja', value:'realisasi', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
        {text:'Keterangan Validasi', value:'keterangan_validasi', sortable:false, class:'header-index-profile'},
      ],
      headers_verifikasi:[
        {text:'Rencana/Sasaran Kinerja', value:'sasaran', sortable:false, class:'header-index-profile', width:'42%'},
        {text:'Indikator Kinerja', value:'indikator', sortable:false, class:'header-index-profile', width:'35%'},
        {text:'Jenis SKP', value:'jenis', sortable:false, class:'header-index-profile', width:'20%'},
        {text:'Target Kinerja', value:'target', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
        {text:'Keterangan Verifikasi', value:'verifikasi', sortable:false, class:'header-index-profile'},
      ],
      items:[{
        no:1, nama_kegiatan:'a', no_sk:'a', keterangan:'a'
      }],
      responseData:{},
      pegawai:[],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableHeader:true,
        enableDetail:false,
        enableCreate:false,
      },
      triwulanFromRoute: null,
      min_rate: 0,
      max_rate: 0,
      year: null,
      di_bawah_ekspektasi: true,
      di_atas_ekspektasi: true,
      sesuai_ekspektasi: true,
      showEmojiPicker: false,
      formVerifikasi: {},
      dialogTerimaVerifikasi: false,
      dialogTolakVerifikasi: false,
      formTerimaKeterangan: "",
      idSKP:null,
      triwulan_berjalan:0,
      dialogKeteranganJawaban: false,
      formKeteranganJawaban: {},
      disabledSimpanKeterangan: false,
      capaian: null,
      dataBawahan: [],
      nip_bawahan: null,
      dialogKinerjaOrganisasi: false,
      formKinerjaOrganisasi:{}
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.triwulanFromRoute = to.query.jenis
    })
  },
  beforeCreate(){
    // SKPService.getDataValidasiSKPTriwulan(1).then(response =>{
    //   this.responseData = response.data
    //   this.triwulan = this.responseData.triwulan_berjalan
    //   this.triwulan_berjalan = parseInt(this.responseData.triwulan_berjalan)
    //   this.pegawai=response.data.data
    // })
    let current = store.state.user.current
    let user = store.state.user
    if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
      current.username = _g.overrideWithPLT(user.current.role)
    }else{
      current.username = user.current.username
    }

    var base_url_api = _g.getBaseURLAPIERK(current.year)
    const url_get_data = base_url_api + 'v1/validasi/get-data'
    axios.post(url_get_data, {
      nip: current.username,
      bulan: this.triwulan,
    }).then(response => {
      this.responseData = response.data
      this.triwulan = this.responseData.triwulan_berjalan
      this.triwulan_berjalan = parseInt(this.responseData.triwulan_berjalan)
      this.pegawai=response.data.data
    }).finally(()=>{
    }).catch(err => {
      var error = err.message ? err.message : err.response.data.message
      this.loadingEl = -1
      store.commit('snackbar/setSnack',{message: error, color:'error'})
    })
  },

  watch:{
    triwulan:function(val){
      console.log(val)
      if(this.triwulanFromRoute != null){
        if(val != this.triwulanFromRoute){
          this.triwulan = val
        }else{
          this.triwulan = this.triwulanFromRoute
        }
      }else{
        this.triwulan = val
      }
      if(this.nip_bawahan != null){
        this.getBawahanSKPPerNIP()
      }else{
        this.updateTable()
      }
      // this.updateTable()
    },
    'formValidasi.rating': function(val){
      if(val == 5){
        this.di_atas_ekspektasi = false
      }else{
        this.di_atas_ekspektasi = true
      }
    }
  },

  created(){
    // this.year = new Date().getFullYear()-1
    this.year = this.$store.getters["user/data"].tahun
    this.getDataBawahan()
    this.updateTable()
  },

  computed:{
    triwulan_text(){
      return isNaN(this.triwulan ) ? 'Tahunan' : 'Triwulan ' + this.triwulan
    },

    parseFile(){
      if(!this.formDetail.file){
        return '-'
      }else{
        return JSON.parse(this.formDetail.file)
      }
    },

    debounceEvaluasiUmpanBalik: {
      get() { return this.text; },
      set: _.debounce(function(newValue) {
        this.formValidasi.umpan_balik = newValue
      }, 100)
    },
    debounceEvaluasiUmpanBalikFeedback: {
      get() { return this.text; },
      set: _.debounce(function(newValue) {
        this.formValidasi.umpan_balik_feedback = newValue
      }, 100)
    },
    debounceEvaluasiAnalisisKinerja: {
      get() { return this.text; },
      set: _.debounce(function(newValue) {
        this.formValidasi.evaluasi_analisis_kinerja = newValue
      }, 100)
    },
    debounceTerimaKeterangan:{
      get() { return this.text; },
      set: _.debounce(function(newValue) {
        this.formTerimaKeterangan = newValue
      }, 100)
    },
    debounceTolakKeterangan:{
      get() { return this.text; },
      set: _.debounce(function(newValue) {
        this.formTolakKeterangan = newValue
      }, 100)
    },
    debounceKeteranganBobot:{
      get() { return this.text; },
      set: _.debounce( function(newValue) { this.formKeteranganJawaban.keterangan_bobot = newValue }, 100)
    },
    isAsisten() {
      return _g.isAsisten()
    },

  },

  methods:{
    // parseFilename(filename){
    //   let parsedFilename = /\d+_(.*)-[\w\d]+(\.\w+)/g.exec(filename)
    //   try{
    //     return parsedFilename[1] + parsedFilename[2]
    //   } catch(e){
    //     return filename
    //   }
    // },

    urlEvidence(filename){
      return 'https://kinerja.bandung.go.id/'+ this.year +'/img/skp-tahunan/'+(typeof filename.name !== 'undefined' ? filename.name : filename)
    },

    urlEvidenceTw(filename){
      return 'https://kinerja.bandung.go.id/'+ this.year +'/img/skp-triwulan/'+(typeof filename.name !== 'undefined' ? filename.name : filename)
    },

    checkRadio(item){
      var capaian = null
      this.capaian = capaian
      if(item.realisasi_kuantitas != null){
        if(item.realisasi_kuantitas >= item.target_bawah && item.realisasi_kuantitas <= item.target_atas){
            capaian = 100;
        }else if(item.target_atas == null || (item.realisasi_kuantitas < item.target_bawah)){
            if(item.is_pembalik){
                capaian = (1+(1-parseFloat(item.realisasi_kuantitas)/parseFloat(item.target_bawah)))*100;
            }else{
                capaian = (parseFloat(item.realisasi_kuantitas)/parseFloat(item.target_bawah))*100;
            }
        }else {
            capaian = (parseFloat(item.realisasi_kuantitas)/parseFloat(item.target_atas))*100;
        }
      }
      if(capaian == null){
        if(item.realisasi_kualitas != null){
          if(item.realisasi_kualitas >= item.kualitas_batas_bawah && item.realisasi_kualitas <= item.kualitas_batas_atas){
              capaian = 100;
          }else if(item.kualitas_batas_atas == null || item.realisasi_kualitas < item.kualitas_batas_bawah){
              if(item.is_pembalik){
                  capaian = (1+(1-parseFloat(item.realisasi_kualitas)/parseFloat(item.kualitas_batas_bawah)))*100;
              }else{
                  capaian = (parseFloat(item.realisasi_kualitas)/parseFloat(item.kualitas_batas_bawah))*100;
              }
          }else {
              capaian = (parseFloat(item.realisasi_kualitas)/parseFloat(item.kualitas_batas_atas))*100;
          }
        }
      }
      if(this.year < 2021){
      if(item.realisasi != null){
        if(item.is_pembalik){
          capaian = ((parseFloat(item.target)/parseFloat(item.realisasi))*100).toFixed(2)
        }else{
          capaian = ((parseFloat(item.realisasi)/parseFloat(item.target))*100).toFixed(2)
        }
      }
      }
      if(this.triwulan != 'tahun'){
        if(item.is_pembalik){
            capaian = (1+(1-parseFloat(item.realisasi)/parseFloat(item.target)))*100;
        }else{
            capaian = (parseFloat(item.realisasi)/parseFloat(item.target))*100;
        }
      }

      if(capaian >= 0 && capaian < 100){
        this.di_bawah_ekspektasi = false
        this.di_atas_ekspektasi = true
        // this.sesuai_ekspektasi = true
        this.sesuai_ekspektasi = false
      }
      if(capaian == 100){
        this.di_bawah_ekspektasi = false
        this.di_atas_ekspektasi = false
        this.sesuai_ekspektasi = false
      }
      if(capaian > 100){
        this.di_bawah_ekspektasi = false
        this.di_atas_ekspektasi = false
        this.sesuai_ekspektasi = false
      }
      this.capaian = capaian
      return capaian
    },

    keteranganRating(idx){
      return idx ? this.ratingList[idx-1] : '-'
    },

    rangeNilai(rating){
      if(this.year >= 2023){
        if (rating == 0) {
          this.min_rate = 0;this.max_rate = 0;
          return '-'
        } else if (rating == 1) {
          this.min_rate = 0;this.max_rate = 50;
          return "Kualitas Mutu: "+this.min_rate+"-"+this.max_rate
        } else if (rating == 2) {
          this.min_rate = 51;this.max_rate = 60;
          return "Kualitas Mutu: "+this.min_rate+"-"+this.max_rate
        } else if (rating == 3) {
          this.min_rate = 61;this.max_rate = 75;
          return "Kualitas Mutu: "+this.min_rate+"-"+this.max_rate
        } else if (rating == 4) {
          this.min_rate = 76;this.max_rate = 90;
          return "Kualitas Mutu: "+this.min_rate+"-"+this.max_rate
        } else if (rating == 5) {
          this.min_rate = 91;this.max_rate = 100;
          return "Kualitas Mutu: "+this.min_rate+"-"+this.max_rate
        }
      }else{
        if (rating == 0) {
          this.min_rate = 0;this.max_rate = 0;
          return '-'
        } else if (rating == 1) {
          this.min_rate = 0;this.max_rate = 50;
          return "Range Nilai: "+this.min_rate+"-"+this.max_rate
        } else if (rating == 2) {
          this.min_rate = 51;this.max_rate = 60;
          return "Range Nilai: "+this.min_rate+"-"+this.max_rate
        } else if (rating == 3) {
          this.min_rate = 61;this.max_rate = 75;
          return "Range Nilai: "+this.min_rate+"-"+this.max_rate
        } else if (rating == 4) {
          this.min_rate = 76;this.max_rate = 90;
          return "Range Nilai: "+this.min_rate+"-"+this.max_rate
        } else if (rating == 5) {
          this.min_rate = 91;this.max_rate = 100;
          return "Range Nilai: "+this.min_rate+"-"+this.max_rate
        }
      }
    },

    defaultStrip(val){
      return val? val : '-'
    },

    detailItem(item){
      item.evaluasi_analisis_kinerja = item.evaluasi_analisis_kinerja ? item.evaluasi_analisis_kinerja.replace(/\[e-([0-9a-fA-F]+)\]/g, (match, hex) => String.fromCodePoint(Number.parseInt(hex, 16))) : item.evaluasi_analisis_kinerja
      item.umpan_balik = item.umpan_balik ? item.umpan_balik.replace(/\[e-([0-9a-fA-F]+)\]/g, (match, hex) => String.fromCodePoint(Number.parseInt(hex, 16))) : item.umpan_balik
      console.log(item)
      this.dialogDetail = true
      this.formDetail = _.clone(item)
    },

    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    updateTable(){
      if(this.triwulan != 0){
        this.pegawai=[]
        this.isLoading = true
        this.loadingBtn.dataBawahan = true
        // SKPService.getDataValidasiSKPTriwulan(this.triwulan).then(response =>{
        //   this.responseData = response.data
        //   this.triwulan_berjalan = parseInt(this.responseData.triwulan_berjalan)
        //   this.pegawai=response.data.data
        //   this.loadingBtn.dataBawahan = false
        //   this.isLoading = false
        // })
        let current = store.state.user.current
        let user = store.state.user
        if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
          current.username = _g.overrideWithPLT(user.current.role)
        }else{
          current.username = user.current.username
        }

        var base_url_api = _g.getBaseURLAPIERK(current.year)
        const url_get_data = base_url_api + 'v1/validasi/get-data'
        this.loadingBtn.dataBawahan = true
        axios.post(url_get_data, {
          nip: current.username,
          bulan: this.triwulan,
        }).then(response => {
          this.responseData = response.data
          this.triwulan_berjalan = parseInt(this.responseData.triwulan_berjalan)
          this.pegawai=response.data.data
          this.loadingBtn.dataBawahan = false
          this.isLoading = false
        }).finally(()=>{
          this.isLoading = false
          this.loadingBtn.dataBawahan = false
        }).catch(err => {
          var error = err.message ? err.message : err.response.data.message
          this.loadingEl = -1
          store.commit('snackbar/setSnack',{message: error, color:'error'})
        })
      }else{
        this.pegawai=[]
        this.isLoading = true
        SKPService.getDataVerifikasiSKP().then(response =>{
          this.responseData = response.data
          this.pegawai=response.data.data
          this.isLoading = false
        })
      }
    },

    terimaSKP(item){
      this.formValidasi =  _.clone(item)
      this.formValidasi.bobot = item.bobot != null ? item.bobot.toString() : null
      this.formValidasi.capaian = this.checkRadio(item)
      if (this.triwulan == 'tahun'){
        SKPService.getAktivitasTerkait(item.id).then(response=>{
          let res = response.data
          this.formValidasi.aktivitas_terkait_skp = res.data
          this.dialogValidasi = true
        })
      } else {
        this.$nextTick(()=>{
          this.dialogValidasi = true
        })
      }
    },

    kirimTerimaSKP(){
      let validasi_id = this.formValidasi.id
      if(this.formValidasi.rating != undefined){
        if (!(this.formValidasi.rating > 0)){
          store.commit('snackbar/setSnack',{message:'Rating tidak boleh kosong', color:'error'})
          return
        }
      }
      if (this.$refs.form.validate()){
        console.log(this.pegawai)
        console.log(this.formValidasi.id)

        this.loadingBtn.validasi=true
        let umpan_balik_emoji = this.formValidasi.umpan_balik
        // let umpan_balik_feedback_emoji = this.formValidasi.umpan_balik_feedback

        // convert semua emoji ke hex
        umpan_balik_emoji = umpan_balik_emoji.replace(/\p{Emoji}/ug, (m) =>`[e-${m.codePointAt(0).toString(16)}]`)
        // umpan_balik_feedback_emoji = umpan_balik_feedback_emoji.replace(/\p{Emoji}/ug, (m, idx) =>`[e-${m.codePointAt(0).toString(16)}]`)

        this.formValidasi.umpan_balik = umpan_balik_emoji
        // this.formValidasi.umpan_balik_feedback = umpan_balik_feedback_emoji

        // convert string ke emoji
        umpan_balik_emoji.replace(/\[e-([0-9a-fA-F]+)\]/g, (match, hex) => String.fromCodePoint(Number.parseInt(hex, 16)))
        // let converted_umpan_balik_feedback_emoji = umpan_balik_feedback_emoji.replace(/\[e-([0-9a-fA-F]+)\]/g, (match, hex) => String.fromCodePoint(Number.parseInt(hex, 16)))

        if(this.triwulan == 'tahun'){
          SKPService.submitValidasiSKPTahunan(this.triwulan, this.formValidasi).then(response=> {
            let res = response.data
            if (res.success){
              store.commit('snackbar/setSnack',{message:res.message, color:'success'})
              this.closeDialog()
              this.updateValidasiPegawai(validasi_id, 1, 'SKP ini sudah divalidasi')
            } else {
              store.commit('snackbar/setSnack',{message:res.message, color:'error'})
              this.formValidasi = {}
              this.$refs.form.reset() // will clear all inputs and reset their validation errors
              this.$refs.form.resetValidation()
            }
          }).finally(()=>{
            this.loadingBtn.validasi=false
          })
        }else{
          // this.formValidasi.kualitas_mutu = this.formValidasi.rating == undefined ? '-' : this.formValidasi.rating
          SKPService.terimaValidasiSKP(this.triwulan, this.formValidasi).then(response=> {
            let res = response.data
            if (res.success){
              store.commit('snackbar/setSnack',{message:res.message, color:'success'})
              this.closeDialog()
              this.updateValidasiPegawai(validasi_id, 1, 'SKP ini sudah divalidasi')
            } else {
              store.commit('snackbar/setSnack',{message:res.message, color:'error'})
              this.formValidasi = {}
              this.$refs.form.reset() // will clear all inputs and reset their validation errors
              this.$refs.form.resetValidation()
            }
          }).finally(()=>{
            this.loadingBtn.validasi=false
          })
        }
      }
    },

    updateValidasiPegawai(id, validasi, keterangan){
      for (let i =0;i< this.pegawai.length; i++){
        let pegawai = this.pegawai[i]
        for (let j = 0; j< pegawai.skp.length; j++){
          let skp = pegawai.skp[j]
          if (skp.id == id){
            this.pegawai[i].skp[j].validasi = validasi
            this.pegawai[i].skp[j].keterangan_validasi = keterangan
            return
          }
        }
      }
    },

    tolakSKP(item){
      this.dialogTolak = true
      this.formTolakKeterangan = ''
      this.idTolak = item.id
      this.dialogTolakVerifikasi = false
    },

    kirimTolakSKP(){
      this.loadingBtn.tolak = true
      SKPService.tolakValidasiSKP(this.triwulan, {id:this.idTolak, keterangan:this.formTolakKeterangan}).then( response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message:res.message, color:'success'})
          this.dialogTolak=false
          this.updateValidasiPegawai(this.idTolak, 2, 'SKP ditolak')
        } else {
          store.commit('snackbar/setSnack',{message:res.message, color:'error'})
        }
      }).finally(()=>{
        this.loadingBtn.tolak = false
      })
    },

    closeDialog(){
      this.formValidasi = {}
      this.$refs.form.reset() // will clear all inputs and reset their validation errors
      this.$refs.form.resetValidation()
      this.dialogValidasi=false
    },

    simpan(ket){
      var data = ket.data
      var keterangan  = ket.ket

      console.log(data.id)
      if(data.tipe === 'terima'){
        SKPService.terimaValidasiSKP(this.triwulan, this.formValidasi).then(response=> {
          let res = response.data
          if (res.success){
            store.commit('snackbar/setSnack',{message:res.message, color:'success'})
            this.hides.push(data.id)
          } else {
            store.commit('snackbar/setSnack',{message:res.message, color:'error'})
          }
        })

      } else if (data.tipe === 'tolak'){
        SKPService.tolakValidasiSKP(this.triwulan,{id:data.id,keterangan:keterangan}).then(response=> {
          let res = response.data
          if (res.success){
            store.commit('snackbar/setSnack',{message:res.message, color:'success'})
            this.hides.push(data.id)
          } else {
            store.commit('snackbar/setSnack',{message:res.message, color:'error'})
          }
        })
      }
    },

    parseFilename(filename){
      let parsedFilename = JSON.parse(filename)
      try{
        return parsedFilename
      } catch(e){
        return filename
      }
    },

    terimaVerifikasiSKP(item){
      this.idSKP = item.id
      this.formVerifikasi =  _.clone(item)
      this.formVerifikasi.jenis = item.bulan != undefined ? item.bulan : 'tahun'
      this.formTerimaKeterangan = ''
      this.dialogTerimaVerifikasi = true
    },

    tolakVerifikasiSKP(item){
      this.idSKP = item.id
      this.formVerifikasi =  _.clone(item)
      this.formVerifikasi.jenis = item.bulan != undefined ? item.bulan : 'tahun'
      this.formTolakKeterangan = ''
      this.dialogTolakVerifikasi = true
    },

    kirimTerimaVerifikasi(){
      this.loadingBtn.terima = true
      SKPService.storeVerifikasiSKP(this.idSKP, this.formVerifikasi.jenis, true, this.formTerimaKeterangan).then( response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message:res.message, color:'success'})
          this.dialogTerimaVerifikasi=false
          this.triwulan = 0
          this.updateTable()
        } else {
          store.commit('snackbar/setSnack',{message:res.message, color:'error'})
        }
      }).finally(()=>{
        this.loadingBtn.terima = false
      })
    },

    kirimTolakVerifikasi(){
      this.loadingBtn.tolak = true
      SKPService.storeVerifikasiSKP(this.idSKP, this.formVerifikasi.jenis, false, this.formTolakKeterangan).then( response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message:res.message, color:'success'})
          this.dialogTolak=false
          this.triwulan = 0
          this.updateTable()
        } else {
          store.commit('snackbar/setSnack',{message:res.message, color:'error'})
        }
      }).finally(()=>{
        this.loadingBtn.tolak = false
      })
    },

    openDialogKeterangan(){
      if(this.year >= 2023){
        // masukan alasan
        this.dialogKeteranganJawaban = this.capaian == 100 && this.formValidasi.bobot == 3 ? true : false
        this.debounceKeteranganBobot = null
        this.formKeteranganJawaban.keterangan_bobot = null
        let item = document.getElementById("btn-simpan-keterangan")
        if(item.textContent != 'Simpan'){
          item.textContent = 'Simpan'
        }
      }
    },

    simpanKeteranganJawaban(){
      if (this.$refs.formKeteranganJawaban.validate()){
        this.disabledSimpanKeterangan = true
        let item = document.getElementById("btn-simpan-keterangan");
        item.textContent = 'Silahkan tunggu'
        SKPService.storeKeteranganBobotSKPTriwulan(
          this.formValidasi.id,
          this.formValidasi.nip18,
          this.formKeteranganJawaban.keterangan_bobot
        ).then(response=>{
          store.commit('snackbar/setSnack',{message:response.data.message,color:response.data.response.toLowerCase()})
          item.textContent = 'Sukses!'
          this.dialogKeteranganJawaban = false
          this.disabledSimpanKeterangan = false
        })
        // let current = store.state.user.current
        // let user = store.state.user
        // var base_url_api = _g.getBaseURLAPIERK(current.year)
        // const url = base_url_api + 'v1/store-keterangan-bobot-skp-triwulan'
        // if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        //   current.username = _g.overrideWithPLT(user.current.role)
        // }else{
        //   current.username = user.current.username
        // }
        // axios.post(url, {
        //   id: this.formValidasi.id,
        //   nip: this.formValidasi.nip18,
        //   nip_atasan: current.username,
        //   keterangan_bobot: this.formKeteranganJawaban.keterangan_bobot,
        //   api:1
        // }).then(response => {
        //   let res = response.data
        //   if (res.success){
        //     store.commit('snackbar/setSnack',{message:response.data.message,color:response.data.response.toLowerCase()})
        //     this.formKeteranganJawaban.keterangan_bobot = ''
        //     item.textContent = 'Sukses!'
        //   } else {
        //     store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        //   }
        // }).finally(()=>{
        //   this.dialogKeteranganJawaban = false
        //   this.disabledSimpanKeterangan = false
        // }).catch(err => {
        //   var error = err.message ? err.message : err.response.data.message
        //   this.loadingEl = -1
        //   store.commit('snackbar/setSnack',{message: error, color:'error'})
        // })
      }
    },

    getDataBawahan(){
      this.loadingBtn.dataBawahan = true
      SKPService.getBawahanValidasiSKP(this.triwulan).then( response => {
        let res = response.data
        if (res.status){
          var temp = []
          if(res.data){
            temp.push({
                "peg_nip": "all",
                "peg_nama":"-- Pilih Semua --"
            })
            res.data.forEach(el => {
              let e = el.split(" - ")
              if(!isNaN(e[0].substring(0,1)) || e[0].substring(0,3) == "PLT"){
                temp.push({
                  "peg_nip":e[0],
                  "peg_nama":e[1]
                })
              }else{
                temp.push({
                  "peg_nip":e[1],
                  "peg_nama":e[0]
                })
              }
            });
          }
          this.dataBawahan = temp
          store.commit('snackbar/setSnack',{message:res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message:res.message, color:'error'})
        }
        this.loadingBtn.dataBawahan = false
      }).finally(()=>{
        this.loadingBtn.dataBawahan = false
      })
    },

    getBawahanSKPPerNIP(){
      this.loadingBtn.dataBawahan = true
      this.pegawai = []
      if(this.nip_bawahan == "all"){
        this.updateTable()
      }else{
        this.isLoading = true
        SKPService.getBawahanValidasiSKPPerNIP(this.nip_bawahan, this.triwulan).then( response => {
          let res = response.data
          if (res.success){
            this.pegawai = res.data
            if(res.data.length > 0){
              this.pegawai[0].username = res.data[0].peg_nip
              this.pegawai[0].nama = res.data[0].peg_nama
              this.pegawai[0].nip = res.data[0].peg_nip
              this.pegawai[0].jabatan = res.data[0].jabatan_nama
              this.pegawai[0].foto = res.data[0].foto
            }
            store.commit('snackbar/setSnack',{message:res.message, color:'success'})
          } else {
            store.commit('snackbar/setSnack',{message:res.message, color:'error'})
          }
          this.loadingBtn.dataBawahan = false
          this.isLoading = false
        }).finally(()=>{
          this.loadingBtn.dataBawahan = false
        })
      }
    },

    onPasteKeterangan(){
      store.commit('snackbar/setSnack',{message:'Mohon maaf tidak bisa copy-paste. :)', color:'warning', centered:true})
      this.preventDefault()
    },

    openDialogKinerjaOrganisasi(item){
      this.formKinerjaOrganisasi = {}
      for (let i = 0; i < this.pegawai.length; i++) {
        const e = this.pegawai[i];
        if(e.nip == item.nip){
          this.pegawai = e
        }
      }
      this.formKinerjaOrganisasi =  _.clone(item)
      this.formKinerjaOrganisasi.bobot = item.bobot != null ? item.bobot.toString() : null
      this.$nextTick(()=>{
        this.dialogKinerjaOrganisasi = true
      })
    },

    closeDialogKinerjaOrganisasi(){
      this.formKinerjaOrganisasi = {}
      this.$refs.formKinerjaOrganisasi.reset()
      this.$refs.formKinerjaOrganisasi.resetValidation()
      this.dialogKinerjaOrganisasi=false
      this.updateTable()
    },

    submitNilaiKinerjaOrganisasi(){
      if(this.formKinerjaOrganisasi.rating != undefined){
        if (!(this.formKinerjaOrganisasi.rating > 0)){
          store.commit('snackbar/setSnack',{message:'Rating tidak boleh kosong', color:'error'})
          return
        }
      }
      if (this.$refs.formKinerjaOrganisasi.validate()){
        console.log(this.pegawai)
        console.log(this.formKinerjaOrganisasi.id)

        this.loadingBtn.validasi=true
        let evaluasi = this.formKinerjaOrganisasi.evaluasi

        // convert semua emoji ke hex
        evaluasi = evaluasi.replace(/\p{Emoji}/ug, (m) =>`[e-${m.codePointAt(0).toString(16)}]`)
        this.formKinerjaOrganisasi.evaluasi = evaluasi

        // convert string ke emoji
        evaluasi.replace(/\[e-([0-9a-fA-F]+)\]/g, (match, hex) => String.fromCodePoint(Number.parseInt(hex, 16)))

        let username = _g.getCurrentUsername()
        axios.post(_g.getBaseURLAPIERK(this.year, "v1/submit-nilai-kinerja-organisasi"),{
            api:1,
            nip: username,
            nip_bawahan: this.formKinerjaOrganisasi.nip,
            evaluasi: this.formKinerjaOrganisasi.evaluasi,
            bobot: this.formKinerjaOrganisasi.bobot,
            kualitas_mutu: this.formKinerjaOrganisasi.kualitas_mutu,
            triwulan: this.triwulan
          }).then(response => {
            let res = response.data
          if (res.success){
            store.commit('snackbar/setSnack',{message:res.message, color:'success'})
            this.closeDialogKinerjaOrganisasi()
          } else {
            store.commit('snackbar/setSnack',{message:res.message, color:'error'})
            this.formKinerjaOrganisasi = {}
            this.$refs.formKinerjaOrganisasi.reset() // will clear all inputs and reset their validation errors
            this.$refs.formKinerjaOrganisasi.resetValidation()
          }
        }).finally(()=>{
          this.loadingBtn.validasi=false
        })

        // SKPService.submitNilaiKinerjaOrganisasi(this.triwulan, this.formKinerjaOrganisasi).then(response=> {
        //   let res = response.data
        //   if (res.success){
        //     store.commit('snackbar/setSnack',{message:res.message, color:'success'})
        //     this.closeDialog()
        //   } else {
        //     store.commit('snackbar/setSnack',{message:res.message, color:'error'})
        //     this.formKinerjaOrganisasi = {}
        //     this.$refs.formKinerjaOrganisasi.reset() // will clear all inputs and reset their validation errors
        //     this.$refs.formKinerjaOrganisasi.resetValidation()
        //   }
        // }).finally(()=>{
        //   this.loadingBtn.validasi=false
        // })
      }
    },

  },
}
</script>

<style lang="css" scoped>
.home-page {
  padding-top: 50px;
}
* {
  box-sizing: border-box;
}
.textarea-emoji-picker {
  position: relative;
  width: 400px;
  margin: 0 auto;
}
.textarea {
  width: 100%;
  min-height: 300px;
  outline: none;
  box-shadow: none;
  padding: 10px 28px 10px 10px;
  font-size: 15px;
  border: 1px solid #BABABA;
  color: #333;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0 rgba(0,0,0,0.1) inset;
  resize: vertical;
}
.emoji-mart {
  position: absolute;
  top: 33px;
  right: 10px;
}
.emoji-trigger {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  height: 20px;
}
.emoji-trigger path {
  transition: 0.1s all;
}
.emoji-trigger:hover path {
  fill: #000000;
}
.emoji-trigger.triggered path {
  fill: darken(#FEC84A, 15%);
}
</style>
