<template lang="html">
  <div class="text-center">
    <v-dialog
      v-if="enableDebug"
      v-model="dialog"
      width="800"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="indigo"
          dark
          x-small
          fab
          class="ma-1"
          v-bind="attrs"
          v-on="on"
        >
        <v-icon small>mdi-bug</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline yellow lighten-2">
          Debug
        </v-card-title>
        <v-card-text>
          <pre v-html="prettyJSON"></pre>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import prettyPrintJson from 'pretty-print-json'
import {parseBoolean} from '@/utils/lib'
import 'pretty-print-json/dist/pretty-print-json.css'

export default {
  props:{
    item: [Object, Array]
  },
  data(){
    return{
      dialog:false
    }
  },
  computed:{
    enableDebug(){
      return parseBoolean(process.env.VUE_APP_ENABLE_DEBUG)
    },

    prettyJSON(){
      let prettyJSON = prettyPrintJson.toHtml(this.item)
      return prettyJSON
    }
  }
}
</script>

<style lang="css" scoped>
</style>
